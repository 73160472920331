import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useMediaQuery from "../hooks/useMediaQuery";

const ImageComparison = ({ beforeSrc, afterSrc, title, description }) => {
  const { t } = useTranslation();
  const [sliderPosition, setSliderPosition] = useState(50);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeImage, setActiveImage] = useState("before");
  const containerRef = useRef(null);
  const sliderRef = useRef(null);
  const imageRef = useRef(null);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const handleMouseMove = (event) => {
    if (sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      const x = Math.max(0, Math.min(event.clientX - rect.left, rect.width));
      setSliderPosition((x / rect.width) * 100);
    }
  };

  const handleTouchMove = (event) => {
    event.preventDefault();
    handleMouseMove(event.touches[0]);
  };

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      slider.addEventListener("mousemove", handleMouseMove);
      slider.addEventListener("touchmove", handleTouchMove);
    }
    return () => {
      if (slider) {
        slider.removeEventListener("mousemove", handleMouseMove);
        slider.removeEventListener("touchmove", handleTouchMove);
      }
    };
  }, []);

  useEffect(() => {
    const updateImageSize = () => {
      if (containerRef.current && imageRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const viewportHeight = window.innerHeight;
        const maxHeight = viewportHeight * 0.85;

        const img = imageRef.current;
        const naturalRatio = img.naturalWidth / img.naturalHeight;

        let width = containerWidth;
        let height = containerWidth / naturalRatio;

        if (height > maxHeight) {
          height = maxHeight;
          width = height * naturalRatio;
        }

        setImageSize({ width, height });
      }
    };

    const img = new Image();
    img.onload = updateImageSize;
    img.src = beforeSrc;

    window.addEventListener("resize", updateImageSize);
    return () => window.removeEventListener("resize", updateImageSize);
  }, [beforeSrc]);

  const handleImageClick = () => {
    if (isMobile) {
      setIsModalOpen(true);
      setActiveImage("before");
    }
  };

  const toggleImage = () => {
    setActiveImage(activeImage === "before" ? "after" : "before");
  };

  const beforeOpacity = Math.min(1, sliderPosition / 50);
  const afterOpacity = Math.min(1, (100 - sliderPosition) / 50);

  return (
    <div className="flex flex-col mb-12" ref={containerRef}>
      {title && (
        <h3 className="text-xl font-semibold text-indigo-700 mb-4">
          {t(title)}
        </h3>
      )}
      {description && <p className="text-gray-600 mb-4">{t(description)}</p>}
      <div
        className="relative mx-auto rounded-xl overflow-hidden shadow-lg"
        ref={sliderRef}
        style={{
          width: `${imageSize.width}px`,
          height: `${imageSize.height}px`,
        }}
        onClick={handleImageClick}
      >
        <img
          ref={imageRef}
          alt={t("imageComparison.beforeAlt")}
          src={beforeSrc}
          className="absolute top-0 left-0 w-full h-full object-contain"
        />
        <div
          className="absolute top-0 left-0 w-full h-full overflow-hidden"
          style={{ clipPath: `inset(0 0 0 ${sliderPosition}%)` }}
        >
          <img
            alt={t("imageComparison.afterAlt")}
            src={afterSrc}
            className="absolute top-0 left-0 w-full h-full object-contain"
          />
        </div>
        <div
          className="absolute top-0 bottom-0 w-1 bg-white cursor-ew-resize"
          style={{ left: `${sliderPosition}%` }}
        >
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-8 h-8 bg-white rounded-full flex items-center justify-center shadow-md">
            <svg
              className="w-4 h-4 text-indigo-700"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
              <path d="M15.41 16.59L10.83 12 15.41 7.41 14 6l-6 6 6 6 1.41-1.41z" />
            </svg>
          </div>
        </div>
        <div
          className="absolute top-5 left-5 bg-white text-indigo-700 px-4 py-2 rounded-full text-base font-semibold transition-opacity duration-200 shadow-md"
          style={{ opacity: beforeOpacity }}
        >
          {t("imageComparison.before")}
        </div>
        <div
          className="absolute top-5 right-5 bg-white text-indigo-700 px-4 py-2 rounded-full text-base font-semibold transition-opacity duration-200 shadow-md"
          style={{ opacity: afterOpacity }}
        >
          {t("imageComparison.after")}
        </div>
        {isMobile && (
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-white text-indigo-700 px-3 py-1 rounded-full text-sm font-semibold shadow-md">
            {t("imageComparison.tapToZoom")}
          </div>
        )}
      </div>

      {isMobile && isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90">
          <div className="relative w-full h-full flex flex-col justify-between">
            <div className="absolute top-0 left-0 right-0 flex justify-between items-center p-4 bg-gradient-to-b from-black to-transparent">
              <button
                className="text-white text-2xl font-bold"
                onClick={() => setIsModalOpen(false)}
              >
                {t("imageComparison.modal.close")}
              </button>
            </div>
            <img
              src={activeImage === "before" ? beforeSrc : afterSrc}
              alt={
                activeImage === "before"
                  ? t("imageComparison.before")
                  : t("imageComparison.after")
              }
              className="w-full h-full object-contain"
            />
            <div
              className="absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-50 text-white px-6 py-3 rounded-full text-2xl font-bold shadow-lg transition-opacity duration-300 ease-in-out"
              style={{
                opacity: isModalOpen ? 1 : 0,
              }}
            >
              {activeImage === "before"
                ? t("imageComparison.before")
                : t("imageComparison.after")}
            </div>
            <div className="absolute bottom-0 left-0 right-0 flex justify-center p-4 bg-gradient-to-t from-black to-transparent">
              <button
                className="bg-white text-indigo-700 px-6 py-2 rounded-full text-lg font-semibold shadow-lg transition-all duration-300 hover:bg-indigo-100"
                onClick={toggleImage}
              >
                {activeImage === "before"
                  ? t("imageComparison.modal.viewAfter")
                  : t("imageComparison.modal.viewBefore")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageComparison;
